<script src="../http.js"></script>
<template>
    <div class="body">
        <top></top>
        <!-- 广告图 -->
        <div class="advertisement_box">
            <div class="advertisement">
                <img :src="ColumnImgSrc" alt="" />
            </div>
        </div>
        <!-- 搜索框 -->
        <!-- <div :class="scrollTop > 150 ? 'fixedSearch' : ''" class="seek">
            <div :class="scrollTop > 150 ? 'fixedSearchNext' : ''" class="seek-input">
                <div class="seek-box">
                    <div class="seek-one">
                        <div class="seek-one-icon">
                            <img src="../assets/home/seek.png" alt="" />
                        </div>
                        <div class="seek-one-input">
                            <input type="font" placeholder="搜索您想要的内容" v-model="keywords" @keyup.enter="toSearch" />
                        </div>
                        <div class="seek-one-button">
                            <button @click="toSearch">搜索</button>
                        </div>
                    </div>
                </div> -->
        <!-- 搜索框下面的文字 -->
        <!-- <div :class="scrollTop > 150 ? 'disNone' : ''" class="seek_input_font">
                    <ul>
                        <li>搞笑视频段子</li>
                        <li>沙雕段子</li>
                        <li>情侣笑话</li>
                        <li>营销文章</li>
                        <li>职场经验</li>
                        <li>节日剧本</li>
                    </ul>
                </div>
            </div>
        </div> -->
        <!-- 当前位置 -->
        <div class="place_box">
            <div class="place">
                <div class="place_image">
                    <img src="../assets/Search_page/109.png" alt="" />
                </div>
                <!-- 位置 -->
                <div class="place_font">
                    <p>当前位置：</p>
                    <p @click="toIndex">首页</p>
                    <p>></p>
                    <p @click="toPage" v-if="type==2">会员福利</p>
                    <p @click="toPage" v-else>{{ classIfyChange }}</p>
                    <p>></p>
                    <p>正文</p>
                </div>
            </div>
        </div>
        <!-- 大盒子 -->
        <div class="box_content">
            <div class="box">
                <!-- 左边的内容 -->
                <div class="box_left">
                    <!-- 左边内容的第一块 -->
                    <div class="box_left_one">
                        <div class="box_left_one_a">
                            <!-- 标题 -->
                            <!-- <h1>{{ ContentData.description }}</h1> -->
                            <h1>{{ ContentData.title }}</h1>
                            <!-- 头像+昵称+来源+日期+ 阅读量+收藏 -->
                            <div class="box_left_one_content">
                                <!-- 头像 -->
                                <div class="head_image">
                                    <img src="../assets/home/1.png" alt="" />
                                </div>
                                <!-- 内容 -->
                                <div class="head_font">
                                    <p>
                                        叶半仙蛙哥 | 来源：{{ ContentData.source }} | 发布日期：{{
                                                 ContentData.updated_at
                                          }}
                                        | 阅读量：{{ ContentData.read_num }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 左边内容的第二块 -->
                    <div class="box_left_tow">
                        <!-- 内容 -->
                        <div v-html="ContentData.content"></div>
                    </div>
                </div>
                <!-- 右边的内容 -->
                <div class="box_right">
                    <!-- 热门阅读 -->
                    <!-- 右边内容的第一部分 -->
                    <div class="box_right_box">
                        <div class="box_right_content">
                            <!-- 标题 -->
                            <div class="box_right_box_title">
                                <!-- 前面的竖线 -->
                                <div class="wire"></div>
                                <h2>推荐阅读</h2>
                            </div>
                            <!-- 文字内容 -->
                            <div class="box_right_box_font">
                                <div class="text_font" v-for="item in ReReadData" :key="item.id"
                                    @click="toContent(item.id)">
                                    <div :class="item.className"></div>
                                    <div class="text_font_tow">
                                        <p>{{ item.title }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 右边内容的第二部分 -->
                    <div class="box_right_box box_right_tow">
                        <div class="box_right_content">
                            <!-- 标题 -->
                            <div class="box_right_box_title">
                                <!-- 前面的竖线 -->
                                <div class="wire"></div>
                                <h2>热门阅读</h2>
                            </div>
                            <!-- 文字内容 -->
                            <div class="box_right_box_font">
                                <div class="text_font" v-for="item in HotReadData" :key="item.id"
                                    @click="toContent(item.id)">
                                    <div class="text_font_tow text_font_tow_font">
                                        <p>{{ item.title }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 右边内容的第三部分 -->
                    <div class="box_right_box box_right_three">
                        <div class="box_right_content">
                            <!-- 标题 -->
                            <div class="box_right_box_title">
                                <!-- 前面的竖线 -->
                                <div class="wire"></div>
                                <h2>行业资讯</h2>
                            </div>
                            <!-- 文字内容 -->
                            <div class="box_right_box_font">
                                <div class="text_font text_font_right_font" v-for="item in MessageData" :key="item.id"
                                    @click="toContent(item.id)">
                                    <div class="box_right_three_image">
                                        <img :src="item.imgUrl" alt="" />
                                    </div>
                                    <div class="text_font_tow">
                                        <p>{{ item.title }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 侧边栏 -->
        <sidebar></sidebar>
        <!-- 底部 -->
        <bottom></bottom>
    </div>
</template>
<script>
    import {
        getInformationContent,
        getInformationReRead,
        getInformationHotRead,
        getMessageData,
        informationToSearch,
        getColumnImg,
        getNounContentData
    } from "../http";
    export default {
        data() {
            return {
                scrollTop: 0,
                keywords: "",
                ContentData: {},
                ReReadData: [],
                HotReadData: [],
                MessageData: [],
                ColumnImgSrc: "",
                classIfyChange: "行业资讯",
                type: "" //判断进来的类型
            };
        },
        created: function () {
            window.addEventListener("scroll", this.showSearch);
            //获取内容详情
            let id = this.$route.query.id;
            this.type = this.$route.query.type
            console.log('198', this.type)
            this.getContentData(id);
            //获取推荐阅读
            this.getReRead();
            //获取热门阅读
            this.getHotRead();
            //获取行业资讯
            this.getMessage();
            //获得栏目图
            this.getTheColumnChart();
            //判断是什么分类
            this.changeClassify();

        },
        watch: {
            $route: {
                async handler() {
                    let id = this.$route.query.id;
                    console.log(id);
                    this.getContentData(id);
                },
                deep: true,
            },
        },
        methods: {
            // 页面刷新
            winReload(cond) {
                window.location.reload();
            },
            //吸顶监听
            showSearch() {
                // 获取当前滚动条向下滚动的距离
                let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                // 当页面滚动到高度300px处时，显示搜索框
                // console.log(scrollTop);
                this.scrollTop = scrollTop;
            },
            changeClassify() {
                let count = this.$route.query.count;
                // console.log(count);
                if (count >= 0) {
                    if (count == 0) {
                        this.classIfyChange = "运营教程";
                        // console.log(this.classIfyChange);
                    } else if (count == 1) {
                        this.classIfyChange = "行业咨讯";
                        // console.log(this.classIfyChange);
                    }
                } else {
                    return;
                }
            },
            //获取内容详情
            async getContentData(id) {
                console.log(id);
                //行业资讯
                if (this.type == 1) {
                    let ContentData = await getInformationContent(id);
                    this.ContentData = ContentData;
                    return
                }
                //会员福利
                if (this.type == 2) {
                    let ContentData = await getNounContentData(id);
                    this.ContentData = ContentData;
                    return
                }
            },
            //获取推荐阅读
            async getReRead() {
                let ReReadData = await getInformationReRead();
                console.log(ReReadData);
                if (ReReadData.length == 1) {
                    ReReadData[0].className = "text_font_one iconfont icon-icon-test2";
                    this.ReReadData = ReReadData;
                } else if (ReReadData.length == 2) {
                    ReReadData[0].className = "text_font_one iconfont icon-icon-test2";
                    ReReadData[1].className = "text_font_one iconfont icon-icon-test";
                    this.ReReadData = ReReadData;
                } else if (ReReadData.length == 3) {
                    ReReadData[0].className = "text_font_one iconfont icon-icon-test2";
                    ReReadData[1].className = "text_font_one iconfont icon-icon-test";
                    ReReadData[2].className = "text_font_one iconfont icon-icon-test1";
                    this.ReReadData = ReReadData;
                } else if (ReReadData.length == 4) {
                    ReReadData[0].className = "text_font_one iconfont icon-icon-test2";
                    ReReadData[1].className = "text_font_one iconfont icon-icon-test";
                    ReReadData[2].className = "text_font_one iconfont icon-icon-test1";
                    ReReadData[3].className = "text_font_one iconfont icon-icon-test3";
                    this.ReReadData = ReReadData;
                } else if (ReReadData.length == 5) {
                    ReReadData[0].className = "text_font_one iconfont icon-icon-test2";
                    ReReadData[1].className = "text_font_one iconfont icon-icon-test";
                    ReReadData[2].className = "text_font_one iconfont icon-icon-test1";
                    ReReadData[3].className = "text_font_one iconfont icon-icon-test3";
                    ReReadData[4].className = "text_font_one iconfont icon-icon-test4";
                    this.ReReadData = ReReadData;
                } else {
                    let newArr = [];
                    ReReadData.forEach((item, index) => {
                        if (index <= 4) {
                            newArr.push(item)
                        }
                    });
                    newArr[0].className = "text_font_one iconfont icon-icon-test2";
                    newArr[1].className = "text_font_one iconfont icon-icon-test";
                    newArr[2].className = "text_font_one iconfont icon-icon-test1";
                    newArr[3].className = "text_font_one iconfont icon-icon-test3";
                    newArr[4].className = "text_font_one iconfont icon-icon-test4";
                    this.ReReadData = newArr;
                }
                // this.ReReadData = ReReadData;
            },
            //获取热门阅读
            async getHotRead() {
                let HotReadData = await getInformationHotRead();
                let newArr = [];
                HotReadData.forEach((item, index) => {
                    if (index <= 4) {
                        newArr.push(item)
                    }
                });
                this.HotReadData = newArr;
                console.log(HotReadData);
            },
            //获取行业资讯
            async getMessage() {
                let messageData = await getMessageData();
                console.log(messageData);
                this.MessageData = messageData;
            },
            //跳转到详情页
            toContent(id) {
                this.$router.push({
                    path: "/Content",
                    query: {
                        id,
                        page: 5,
                        type: 1
                    },
                });
                this.winReload()
            },
            //去搜索
            async toSearch() {
                console.log(this.keywords);
                let content = await informationToSearch(this.keywords);
                console.log(content);
                let id = content.id;
                this.$router.push({
                    path: "/Content",
                    query: {
                        id,
                    },
                });
            },
            //获得栏目图
            async getTheColumnChart() {
                let imgSrc = await getColumnImg();
                this.ColumnImgSrc = imgSrc;
                console.log(this.ColumnImgSrc);
            },
            //去首页
            toIndex() {
                this.$router.push({
                    path: "/Index",
                });
            },
            //点击跳转页面
            toPage() {
                if (this.type == 2) {
                    this.$router.push({
                        path: "/Personal",
                        query: {
                            count: 1
                        }
                    });
                    return
                }
                if ((this.classify = "运营教程")) {
                    this.$router.push({
                        path: "/Information",
                        query: {
                            page: 5,
                            count: 0
                        }
                    });
                    return
                }
                if ((this.classify = "行业资讯")) {
                    this.$router.push({
                        path: "/Information",
                        query: {
                            page: 5,
                            count: 1
                        }
                    });
                    return
                }

            }
            // tow() {
            //   this.$router.push("/Text");
            // },
        },
        destroyed() {
            window.removeEventListener("scroll", this.showSearch);
        },
    };
</script>

<style scoped>
    /* input吸顶效果 */

    .fixedSearch {
        position: fixed;
        /* width: 100%; */
        z-index: 2;
        height: 60px !important;
        background: rgb(255, 255, 255);
        top: 60px;
        /* overflow: hidden; */
    }

    .fixedSearchNext {
        height: 60px !important;
    }

    .disNone {
        display: none;
    }

    /* 吸顶效果结束 */
    /* 页面 */

    .body {
        width: 100%;
        min-width: 1200px;
        background-color: #fafafa;
    }

    /* 广告 */

    .advertisement_box {
        width: 100%;
        min-width: 1200px;
        margin: auto;
        padding-top: 70px;
        margin-bottom: 30px;
        margin-top: 10px;
        background-color: #ffffff;
    }

    .advertisement {
        width: 1200px;
        margin: auto;
    }

    .advertisement img {
        width: 1200px;
    }

    /* 搜索 */

    .seek {
        width: 100%;
        height: 130px;
        min-width: 1200px;
        background-color: #ffffff;
    }

    .seek-input {
        width: 1200px;
        height: 130px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    .seek-box {
        width: 750px;
        height: 60px;
        display: flex;
        align-items: center;
    }

    .seek-one {
        display: flex;
        align-items: center;
        border: 1px #f62959 solid;
        border-radius: 8px;
    }

    .seek-one-icon {
        width: 24px;
        height: 24px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 12px;
    }

    .seek-one-icon img {
        width: 100%;
    }

    .seek-one-input {
        width: 590px;
        display: inline-block;
    }

    .seek-one-input input {
        width: 99%;
        line-height: 38px;
        font-size: 16px;
        color: #999999;
        vertical-align: middle;
        background: none;
    }

    .seek-one-input input:checked {
        color: #999999;
    }

    .seek-one-button {
        width: 114px;
        height: 40px;
    }

    .seek-one-button button {
        width: 114px;
        height: 40px;
        background-color: #f62959;
        color: #ffffff;
        border-radius: 0px 8px 8px 0px;
        cursor: pointer;
        font-size: 18px;
    }

    .seek_input_font {
        width: 540px;
        position: relative;
        top: -10px;
    }

    .seek_input_font ul {
        display: flex;
        justify-content: space-between;
    }

    .seek_input_font ul li {
        cursor: pointer;
        font-size: 14px;
        color: #999999;
    }

    .seek_input_font ul li:hover {
        color: #f62959;
    }

    /* 当前位置 */

    .place_box {
        width: 100%;
        min-width: 1200px;
        height: 60px;
        line-height: 60px;
        border-top: 1px #ededed solid;
    }

    .place {
        width: 1200px;
        height: 60px;
        line-height: 60px;
        margin: auto;
        display: flex;
    }

    /* 图标 */

    .place_image img {
        vertical-align: middle;
    }

    /* 位置文字 */

    .place_font {
        display: flex;
    }

    .place_font p {
        margin-left: 10px;
        color: #999999;
        font-size: 14px;
    }

    .place_font p:nth-of-type(n + 2) {
        cursor: pointer;
    }

    /* 大盒子 */

    .box_content {
        width: 100%;
        background-color: white;
    }

    .box {
        width: 1200px;
        margin: auto;
        display: flex;
    }

    /* 盒子左边的内容 */

    .box_left {
        width: 870px;
    }

    /* 盒子左边内容的第一块 */

    .box_left_one {
        width: 100%;
        background-color: #ffffff;
        padding-bottom: 10px;
        border-bottom: 1px #f0f0f0 solid;
    }

    .box_left_one_a {
        width: 94%;
        margin: auto;
    }

    /* 标题 */

    .box_left_one_a h1 {
        font-size: 34px;
        line-height: 60px;
    }

    /* 内容 + 头像+昵称+来源+日期+ 阅读量+收藏 */

    .box_left_one_content {
        width: 100%;
        height: 30px;
        color: #666666;
        display: flex;
        align-items: center;
    }

    /* 头像 */

    .head_image {
        width: 24px;
        height: 24px;
    }

    .head_image img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        vertical-align: middle;
    }

    /* 内容 */

    .head_font {
        font-size: 14px;
        margin-left: 10px;
    }

    .frequency_rigth_button button:nth-of-type(1) {
        color: #ffffff;
        background-color: #f62959;
        box-shadow: 0px 3px 6px 0px rgba(246, 41, 89, 0.2);
    }

    .frequency_rigth_button button:nth-of-type(1) img {
        width: 20px;
        height: 20px;
        vertical-align: middle;
        margin-right: 4px;
    }

    .frequency_rigth_button button:nth-of-type(2) {
        color: #f62959;
        background: none;
        border: 1px #f62959 solid;
        box-shadow: 0px 3px 6px 0px rgba(246, 41, 89, 0.2);
    }

    .frequency_rigth_button button:nth-of-type(2) img {
        width: 20px;
        height: 20px;
        vertical-align: middle;
        margin-right: 4px;
    }

    /* 盒子左边内容的第二块 */

    .box_left_tow {
        width: 100%;
        min-height: 490px;
        margin-top: 30px;
        background-color: #ffffff;
    }

    /* 内容 */

    .box_left_tow_content {
        width: 94%;
        margin: auto;
        overflow: hidden;
    }

    /* 内容文字 */

    .box_left_tow_one {
        width: 100%;
        margin-top: 10px;
    }

    .box_left_tow_one h3 {
        line-height: 40px;
        color: #333333;
    }

    .box_left_tow_one h5 {
        line-height: 30px;
        font-size: 14px;
        color: #333333;
    }

    .box_left_tow_one p {
        font-size: 14px;
        text-indent: 2em;
        color: #333333;
    }

    .box_left_tow_one p>img {
        width: 813px;
        height: 474px;
    }

    /* 左边盒子的第三块 */

    .box_left_three {
        width: 100%;
        min-height: 108px;
    }

    /* 点赞收藏 */

    .box_left_three_content {
        width: 50%;
        height: 108px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
    }

    .box_left_three_button button {
        width: 200px;
        height: 60px;
        border-radius: 10px;
        margin-left: 10px;
        font-size: 24px;
    }

    .box_left_three_button button:nth-of-type(1) {
        color: #ffffff;
        background-color: #f62959;
        box-shadow: 0px 3px 6px 0px rgba(246, 41, 89, 0.2);
    }

    .box_left_three_button button:nth-of-type(1) img {
        width: 30px;
        height: 30px;
        vertical-align: middle;
        margin-right: 8px;
    }

    .box_left_three_button button:nth-of-type(2) {
        color: #f62959;
        background: none;
        border: 1px #f62959 solid;
        box-shadow: 0px 3px 6px 0px rgba(246, 41, 89, 0.2);
    }

    .box_left_three_button button:nth-of-type(2) img {
        width: 30px;
        height: 30px;
        vertical-align: middle;
        margin-right: 8px;
    }

    /* 左边盒子的第四块 */

    .box_left_four {
        width: 100%;
        display: flex;
        align-items: center;
        color: #333333;
    }

    .box_left_four_left {
        width: 50%;
        text-align: center;
        cursor: pointer;
    }

    .box_left_four_left:hover,
    .box_left_four_right:hover {
        color: #f62959;
    }

    .box_left_four_right {
        width: 50%;
        text-align: center;
        cursor: pointer;
    }

    /* 盒子右边内容 */

    .box_right {
        width: 300px;
        margin-left: 30px;
    }

    /* 右边盒子的第一个内容 */

    .box_right_box {
        width: 100%;
    }

    .box_right_content {
        width: 94%;
        margin: auto;
    }

    /* 标题 */

    .box_right_box_title {
        height: 60px;
        display: flex;
        align-items: center;
        border-bottom: 1px #cccccc solid;
    }

    /* 线 */

    .wire {
        width: 5px;
        height: 24px;
        background-color: #f62959;
    }

    .box_right_box_title h2 {
        font-size: 20px;
        margin-left: 10px;
        color: #333333;
    }

    /* 文字内容 */

    .text_font {
        width: 100%;
        /* height: 40px; */
        margin-top: 20px;
        display: flex;
        align-items: center;
        color: #999999;
    }

    .text_font p:hover,
    .text_font:hover {
        color: #f62959 !important;
        cursor: pointer;
    }

    .text_font_one {
        width: 40px;
        height: 40px;
        font-size: 40px;
        line-height: 40px;
        text-align: center;
    }

    .text_font_tow {
        margin-left: 20px;
    }

    .text_font_tow p {
        font-size: 15px;
        color: #333333;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: elipsis;
    }

    /* 右边盒子的第二个内容 */

    .box_right_tow {
        width: 100%;
        margin-top: 30px;
    }

    .text_font_tow_font {
        margin: 0;
    }

    /* 右边盒子的第三个内容 */

    .box_right_three {
        width: 100%;
        margin-top: 30px;
    }

    .box_right_three_image img {
        vertical-align: middle;
        width: 82px;
        height: 57px;
    }

    /* 底部 */

    .corporate_strength-box {
        margin-top: 10px !important;
    }
</style>